import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../utils/constants';
import { Link } from 'gatsby';
// import Img from 'gatsby-image';

import PageHeader from '../../components/pageheader/PageHeader';
import MainLayout from "../../layouts/mainlayout/MainLayout"

function Announcements() {
	const [announcements, setAnnouncements] = useState([]);

	useEffect(() => {
		fetch(`${BASE_URL}/api/open/announcements`)
		.then(response => response.json())
		.then(data => {
			console.log("resp",data);
			setAnnouncements(data.Data);
		})
	}, [])
	return (
		<MainLayout title="Announcements">
			<PageHeader title="Announcements" />
			<div className='announcements-list-layout'>
				<div className="row">
					{
						announcements.map(announcement => {
							return (
								<Link
									to={`/announcement/${announcement.slug}`}
									className="col-12 col-sm-12 col-md-6 col-lg-4 mb-5 no-show-anchor"
								>
									<div className="announcement-item">
										<div className="announcement-image">
											<img src={announcement.thumb} />
										</div>
										<div className="announcement-body">
											<h5>{announcement.title}</h5>
											<div>{announcement.date}</div>
										</div>
									</div>
								</Link>
							)
						})
					}
				</div>
			</div>
		</MainLayout>
	);
}

export default Announcements;